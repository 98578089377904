
// Inspired by https://hackernoon.com/how-css-grid-beats-bootstrap-85d5881cf163

body{
  display: grid;

  // Font
  // https://fonts.google.com/specimen/Source+Sans+Pro
  font-family: "Source Sans Pro";
  // Regular: 400
  // Bold: 700
  // END OF FONT

  // grid-template-columns: repeat(12, 1fr);
}

// Main app header
#header{
  display: grid;
  grid-template-columns: 200px 4fr 250px;
  column-gap: 12px;

  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 12px;
  padding: 12px;

  .gridArea{
    grid-area: auto;
    align-self: center; // Vertical align
    // grid-column: span 1;
  }

  #brandName{
    display: inline-block;

    h1{
      display: inline-block;
    }
  }

  ul{
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
    li{
      display: inline-block;
      margin-right: 12px;
    }
  }

  #signin{
    justify-self: end; // Align to the right
  }
}

// Main app container
#content{
  padding: 12px;
  margin-bottom: 50px; // Allowing to have a fixed footer
}

// Scenario row
#scenarioRow{
  display: grid;
  grid-template-columns: fit-content(75%) fit-content(300px);
  margin-bottom: 12px;
}

.hidden{
  display: none;
}

.wrapText{
  overflow-wrap: anywhere;
}
#footer{
  // 2 columns, with first 1 fitting content
  &.columns{
    grid-template-columns: fit-content(30%) fit-content(30%) 1fr;
  }

  align-items: center;

  background-color: #FFFFFF;

  position: fixed;
  border-top: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 -5px 10px rgba(0,0,0,0.1);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px 12px;
  font-size: 0.6em;

  // All div within the element
  div{
    display: inline-block;
    margin-right: 12px;
  }

  // First level
  > div{
    height: 100%;
  }

  // Vertical borders
  > div:not(:last-child){
    border-right: 1px solid rgba(0,0,0,0.3);
  }
}

#itemSearchBar{
  width: 200px;
}
