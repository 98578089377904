// Adapted from https://www.w3schools.com/howto/howto_css_switch.asp
//
// HTML template:
// label class="switch"
// input type="checkbox"
// span class="slider round"

@switch-height: 1em;
@switch-width: 2em;
@slider-border: 1px;

@switch-pre: @switch-width - @switch-height;

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: @switch-width;
  height: @switch-height;

    /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: calc(@switch-pre - @slider-border * 2);
    width: calc(@switch-pre - @slider-border * 2);
    left: @slider-border;
    bottom: @slider-border;
    background-color: white;
    transition: .4s;
  }
}

// '+' the next DOM element, ie the .slider after the input
input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(@switch-pre);
}

/* Rounded sliders */
.slider.round {
  border-radius: @switch-height;

  &:before {
    border-radius: 50%;
  }
}
