
.popup{
  position: absolute;
  z-index: 999;

  background-color: white;
  box-shadow: 0 3px 5px rgba(0,0,0,0.2);
  border-radius: 3px;

  div{
    padding: 3px 9px;
    // border: 1px solid red;

    &.enabled{
      cursor: pointer;
      &:hover{
        background-color: rgba(0,0,0,0.05);
      }
    }

    &.separator{
      padding: 0;
      margin: 3px 0;
      border: 1px solid #F0F0F0;

      cursor: none;
    }
  }
}
