#modelContainer {
  border-radius: 3px;
}
/* .flex_container{
  display: flex;
  border: 1px solid green;
}

.flex_columns{
  flex-direction: column
}

.model_row{
  height: 30px;
  padding: 12px;
  border: 1px solid red;
}

.model_cell{
  padding: 12px;
  border: 1px solid blue;
}
*/
.TLcell {
  cursor: pointer;
  border-radius: 3px;
  white-space: nowrap;
}
.TLcell:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.model_header {
  font-weight: bold;
}
/* .TLleftColumn{
  flex-basis: 5vw;
} */
.bold {
  font-weight: bold;
}
/* .TLflexGrow1{
  flex-grow: 1;
} */
.link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.display-inline-block {
  display: inline-block;
}
.inline-list {
  padding: 0;
}
.inline-list li {
  display: inline-block;
  margin: 0px 6px;
  padding: 3px 12px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  cursor: pointer;
}
#sensitivityTable td {
  text-align: right;
  padding: 3px 6px;
}
.grid_layout {
  display: grid;
  grid-template-rows: 150px auto 100px;
  grid-template-columns: 3fr;
  border: 1px solid grey;
}
.grid_container {
  display: grid;
  justify-content: center;
  /* position of the content of the grid */
  align-items: start;
  /* position of the cells within the grid areas */
  /* Defines columns and rows */
  /* grid-template-columns: 10%;
  grid-template-rows: 25%; */
  /*  */
  grid-template-columns: 10% 10% 10% 10%;
  grid-auto-columns: 20%;
  grid-auto-flow: row;
  column-gap: 10px;
  row-gap: 10px;
  border: 1px solid grey;
}
.grid_item {
  border: 1px solid blue;
}
.isRowHighlight {
  background-color: rgba(0, 0, 0, 0.1);
}
.isZero {
  color: rgba(0, 0, 0, 0.2);
}
.isHardCoded {
  color: blue;
}
.isFormula {
  text-decoration: underline!important;
}
.isNotSynced {
  border: 1px solid orange;
  box-shadow: 0px 0px 15px orange;
}
.isError {
  background-color: red;
}
.isCircular {
  background-color: orange;
}
.clickable {
  cursor: pointer;
}
body {
  display: grid;
  font-family: "Source Sans Pro";
}
#header {
  display: grid;
  grid-template-columns: 200px 4fr 250px;
  column-gap: 12px;
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 12px;
  padding: 12px;
}
#header .gridArea {
  grid-area: auto;
  align-self: center;
}
#header #brandName {
  display: inline-block;
}
#header #brandName h1 {
  display: inline-block;
}
#header ul {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}
#header ul li {
  display: inline-block;
  margin-right: 12px;
}
#header #signin {
  justify-self: end;
}
#content {
  padding: 12px;
  margin-bottom: 50px;
}
#scenarioRow {
  display: grid;
  grid-template-columns: fit-content(75%) fit-content(300px);
  margin-bottom: 12px;
}
.hidden {
  display: none;
}
.wrapText {
  overflow-wrap: anywhere;
}
#footer {
  align-items: center;
  background-color: #FFFFFF;
  position: fixed;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px 12px;
  font-size: 0.6em;
}
#footer.columns {
  grid-template-columns: fit-content(30%) fit-content(30%) 1fr;
}
#footer div {
  display: inline-block;
  margin-right: 12px;
}
#footer > div {
  height: 100%;
}
#footer > div:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
#itemSearchBar {
  width: 200px;
}
#gridTest {
  border: 1px solid red;
}
.cssGridLayout {
  margin: 3px;
  border: 1px solid #F0F0F0;
}
.cssGridLayout .gridOneRow {
  display: grid;
  column-gap: 6px;
  margin-bottom: 3px;
  justify-items: stretch;
}
.cssGridLayout .gridOneRow div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.cssGridLayout .cellRowTypeHeader {
  font-weight: bold;
  border-radius: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.cssGridLayout .cellRowTypeHeader:first-child,
.cssGridLayout .cellRowTypeHeader:nth-child(2),
.cssGridLayout .cellRowTypeHeader:last-child {
  border-right: none;
}
.cssGridLayout .cellRowTypeContent {
  display: block;
}
.cssGridLayout .cellColTypeCenter {
  text-align: right;
}
.cssGridLayout .cellColTypeLeft {
  text-align: left;
}
.cssGridLayout .cellInside {
  padding: 0 3px;
  vertical-align: text-bottom;
  display: inline;
  text-decoration: inherit;
}
.cssGridLayout .draghandle {
  cursor: grab;
  padding: 0 3px;
  display: inline-block;
  height: 100%;
  vertical-align: bottom;
}
.cssGridLayout .draghandle:hover {
  padding: 0 9px;
  background-color: rgba(0, 0, 0, 0.2);
}
.dndDropzone {
  font: inherit;
}
/*
  parent
    display: grid | inline-grid
    grid-template-columns
    grid-template-rows
      ... ... ...
      repeat( occurence, values)

    grid-auto-columns
    grid-auto-rows
    grid-auto-flow: row | column | dense

    grid-column-start

    grid-template-areas: naming

    grid-auto-flow?

    // Spacing:
    column-gap (previously grid-column-gap)
    row-gap (previously grid-row-gap)
    gap: <grid-row-gap> <grid-column-gap>

    // Children positioning
    justify-items: start | end | center | stretch
    align-items: start | end | center | stretch
    place-items

    justify-content: start | end | center | space-around | space-between | space-evenly
    align-content

      Xfr : fraction of free space

  children:
    grid-column ... grid-column-start + grid-column-end
    grid-row ... grid-row-start + grid-row-end
    grid-area: name | <row-start> / <column-start> / <row-end> / <column-end>
    justify-self: start/end/center/stretch ... along the inline row
    align-self ... along the block column
    place-self: combination of justify-self and align-seld



*/
.cssGrid {
  display: grid;
  column-gap: 6px;
}
.cssGrid.autoColumns {
  grid-auto-columns: max-content;
  grid-auto-flow: column;
}
.cssGrid .gridArea {
  grid-area: auto;
}
.flex {
  display: flex;
}
.flex.extendLastChild :last-child {
  flex-grow: 1;
}
.flex.flexWrap {
  flex-wrap: wrap;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 1em;
  /* Hide default HTML checkbox */
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: calc(1em - 1px * 2);
  width: calc(1em - 1px * 2);
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  transform: translateX(1em);
}
/* Rounded sliders */
.slider.round {
  border-radius: 1em;
}
.slider.round:before {
  border-radius: 50%;
}
.formulaEditor {
  padding-left: 3px;
}
.formulaEditor .close {
  cursor: pointer;
}
.formulaEditor .editorSub {
  margin: 0 3px;
  padding: 3px;
  position: relative;
}
.formulaEditor .editorSub.referenceSelector {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.formulaEditor .editorSub.referenceSelector .pickerSection:not(:last-of-type) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.formulaEditor .editorSub.referenceSelector .pickerSection {
  outline: none;
}
.formulaEditor .editorSub.referenceSelector .pickerSection:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.formulaEditor .editorSub.referenceSelector .pickerPopup {
  position: absolute;
  background-color: #FFFFFF;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 6px;
  left: 0;
  width: 300px;
}
.formulaEditor .editorSub.referenceSelector .pickerPopup div {
  margin: 6px 0;
}
.formulaEditor .editorSub.referenceSelector .pickerPopup ul li {
  cursor: pointer;
}
.formulaEditor .editorSub.referenceSelector .pickerPopup ul li:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.formulaEditor .editorSub.referenceSelector .pickerPopup .close {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.formulaEditor .editorSub .pickerSection {
  padding: 0 6px;
}
.formulaEditor .editor:focus {
  outline: none;
}
.formulaEditorReader {
  margin: 12px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.formulaEditorReader div {
  margin: 6px;
  padding: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}
.popup {
  position: absolute;
  z-index: 999;
  background-color: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.popup div {
  padding: 3px 9px;
}
.popup div.enabled {
  cursor: pointer;
}
.popup div.enabled:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.popup div.separator {
  padding: 0;
  margin: 3px 0;
  border: 1px solid #F0F0F0;
  cursor: none;
}
.cssGridLayout {
  display: grid;
}
.cssGridLayout .gridScrollableContainer {
  padding: 6px;
  overflow: scroll;
}
.cssGridLayout .left {
  grid-column: 1/1;
}
.cssGridLayout .center {
  grid-column: 2/2;
}
.cssGridLayout .right {
  grid-column: 3/3;
}
.cssGridLayout .header {
  grid-row: 1/1;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.cssGridLayout .header::-webkit-scrollbar {
  display: none;
}
.cssGridLayout .content {
  grid-row: 2/2;
}
.scrollingContainer {
  border: 2px solid blue;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.scrollingContainer div {
  border: 1px solid black;
  height: 250px;
  width: 100%;
  overflow: scroll;
}
.scrollingContainer div.bottom .insideCell {
  background: linear-gradient(#e66465, #9198e5);
}
.scrollingContainer div.top {
  background: linear-gradient(90deg, #e66465, #9198e5);
}
.scrollingContainer .insideCell {
  width: 1000px;
  height: 600px;
  padding: 30px;
}
.fullRow {
  width: 100%;
  font-size: 1ch;
  border: 1px solid;
  padding: 0;
  font-variant: proportional-nums;
}
.fullRow.widthFitContent {
  width: fit-content;
  font-size: inherit;
}
.fullRow div {
  border: 1px solid orange;
  box-sizing: border-box;
  width: 4vw;
  height: 1vw;
  display: inline-block;
}
