
.cssGridLayout {
  display: grid;

  .gridScrollableContainer{
    padding: 6px;
    overflow: scroll;
  }

  .left{
    grid-column: 1/1;
  }
  .center{
    grid-column: 2/2;
  }
  .right{
    grid-column: 3/3;
  }

  .header{
    grid-row:1/1;

    // Hide the elevator scrollbar
    &::-webkit-scrollbar{
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .content{
    grid-row:2/2;
  }
}

.scrollingContainer{
  border: 2px solid blue;
  display: grid;
  grid-template-columns: 1fr 1fr;

  div{
    border: 1px solid black;

    height: 250px;
    width: 100%;
    overflow: scroll;

    &.bottom{
      .insideCell{
        background: linear-gradient(#e66465, #9198e5);
      }
    }

    &.top{
      background: linear-gradient(90deg, #e66465, #9198e5);
    }
  }

  .insideCell{
    width: 1000px;
    height: 600px;
    padding: 30px;
  }
}

.fullRow{
  width: 100%;
  font-size: 1ch; // Start with small font

  &.widthFitContent{
    width: fit-content;
    font-size: inherit;
  }
  border: 1px solid;
  padding: 0;

  // font-family: Monospace;
  // font-family: "Source Sans Pro";
  font-variant: proportional-nums;

  div{
    border: 1px solid orange;
    box-sizing: border-box;
    width: 4vw;
    height: 1vw;
    display: inline-block;
  }
}
