// Styling for the cssgrid-app JS module

#gridTest{
  border: 1px solid red;
}

.cssGridLayout{
  margin: 3px;
  border: 1px solid #F0F0F0;

  .gridOneRow{
    display: grid;
    column-gap: 6px;
    margin-bottom: 3px; // Between rows (rows are independent elements, not Grid children
    justify-items: stretch; // Child items take the full space, and only their space

    div{
      overflow: hidden; // Hide content which is bigger than cell
      text-overflow: ellipsis; // Allow text to be shortened with an ellipsis
    }
  }

  .cellRowTypeHeader{
    font-weight: bold;
    border-radius: 0;
    border-right: 1px solid rgba(0,0,0,0.2);

    &:first-child, &:nth-child(2), &:last-child {
      border-right: none;
    }
  }

  .cellRowTypeContent{
    display: block; // Not useful; just to pass a property for the class for debug
    // color: #0F0F0F;
  }

  .cellColTypeCenter{
    text-align: right;
  }

  .cellColTypeLeft{
    text-align: left;
  }

  .cellInside{
    // border-right: 1px solid #F4F4F4;
    padding: 0 3px;
    vertical-align: text-bottom;
    display: inline; // To allow the ellipsis on text
    // display: inline-block; // Allows multiple elements in same line
    text-decoration: inherit; // Receive the class from the cell
  }

  // Defined in JS to be variables
  // .gridDefColumns{
  //   // Xfr instead of X% to manage the grid gap spacing
  //   // grid-template-columns: repeat(5, minmax(0,1fr));
  //   // grid-template-columns: repeat(4, minmax(0,1fr)) // minmax() instead of just Xfr to enforce the size
  // }

  .draghandle{
    cursor: grab;
    padding: 0 3px;
    // border: 2px solid green;
    display: inline-block; // Allows multiple elements in same line
    height: 100%;
    vertical-align: bottom;

    &:hover{
      padding: 0 9px;
      background-color: rgba(0,0,0,0.2);
    }
  }
}

.dndDropzone{
  font: inherit;
  // padding: 3px 12px;
  // border: 2px solid blue;
}
