// Generic Flexbog

.flex{
  display: flex;

  &.extendLastChild{
    // Extend the size of last child
    :last-child{
      flex-grow: 1;
    }
  }

  &.flexWrap{
    flex-wrap: wrap;
  }
}
