// Generic CSS Grid usage

/*
  parent
    display: grid | inline-grid
    grid-template-columns
    grid-template-rows
      ... ... ...
      repeat( occurence, values)

    grid-auto-columns
    grid-auto-rows
    grid-auto-flow: row | column | dense

    grid-column-start

    grid-template-areas: naming

    grid-auto-flow?

    // Spacing:
    column-gap (previously grid-column-gap)
    row-gap (previously grid-row-gap)
    gap: <grid-row-gap> <grid-column-gap>

    // Children positioning
    justify-items: start | end | center | stretch
    align-items: start | end | center | stretch
    place-items

    justify-content: start | end | center | space-around | space-between | space-evenly
    align-content

      Xfr : fraction of free space

  children:
    grid-column ... grid-column-start + grid-column-end
    grid-row ... grid-row-start + grid-row-end
    grid-area: name | <row-start> / <column-start> / <row-end> / <column-end>
    justify-self: start/end/center/stretch ... along the inline row
    align-self ... along the block column
    place-self: combination of justify-self and align-seld



*/

.cssGrid{
  display: grid;
  column-gap: 6px;

  // Make the child columns automatically based on their content
  &.autoColumns{
    grid-auto-columns: max-content;
    grid-auto-flow: column;
  }

  .gridArea {
    grid-area: auto;
  }
}
