
.formulaEditor{
  padding-left: 3px;

  .close{
    cursor: pointer;
  }

  .editorSub{
    // border: 1px solid rgba(0,0,0,0.1); // For development help
    margin: 0 3px;
    padding: 3px;
    position: relative; // Allows to position pickers relative to the Sub

    &.referenceSelector{
      border: 1px solid rgba(0,0,0,0.3);
      border-radius: 3px;

      .pickerSection:not(:last-of-type){
        border-right: 1px solid rgba(0,0,0,0.2);
      }

      .pickerSection{
        outline: none;

        &:focus{
          background-color: rgba(0,0,0,0.1); // For development help
        }
      }

      .pickerPopup{
        position: absolute;
        background-color: #FFFFFF;
        z-index: 1000;
        border: 1px solid rgba(0,0,0,0.2);
        box-shadow: 0 0 3px rgba(0,0,0,0.2);
        padding: 6px;

        left: 0;
        width: 300px;

        div{
          margin: 6px 0;
        }

        ul{
          li{
            cursor: pointer;

            &:hover{
              background-color: rgba(0,0,0,0.2);
            }
          }
        }

        .close{
          border-top: 1px solid rgba(0,0,0,0.2)
        }

      }
    }

    .pickerSection{
      padding: 0 6px;
      // border: 1px solid rgba(0,0,0,0.3);

      // &.pickerClose{
      //   cursor: pointer;
      // }
    }
  }

  .editor{
    &:focus{
      // background-color: rgba(0,0,0,0.1); // For development help
      outline: none;
    }
  }
}

.formulaEditorReader{
  margin: 12px;
  padding: 12px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 3px;

  div{
    margin: 6px;
    padding: 6px;
    border-bottom: 1px solid rgba(0,0,0,0.6);
  }
}
