
#modelContainer{
  border-radius: 3px; // Not really useful; just adding it for the style to be valid
  // border: 1px solid grey;
}

/* .flex_container{
  display: flex;
  border: 1px solid green;
}

.flex_columns{
  flex-direction: column
}

.model_row{
  height: 30px;
  padding: 12px;
  border: 1px solid red;
}

.model_cell{
  padding: 12px;
  border: 1px solid blue;
}
*/

.TLcell{
  cursor: pointer;
  border-radius: 3px;
  white-space: nowrap; // Ensure content is on 1 line only

  &:hover{
    background-color: rgba(0,0,0,0.2);
  }
}

.model_header{
  font-weight: bold;
}
/* .TLleftColumn{
  flex-basis: 5vw;
} */

.bold{
  font-weight: bold;
}
/* .TLflexGrow1{
  flex-grow: 1;
} */

.link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.display-inline-block{
  display: inline-block;
}

.inline-list{
  padding: 0;
}
.inline-list li{
  display: inline-block;
  margin: 0px 6px;
  padding: 3px 12px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  cursor: pointer;
}

#sensitivityTable td{
  text-align: right;
  padding: 3px 6px;
}

.grid_layout{
  display: grid;
  grid-template-rows: 150px auto 100px;
  grid-template-columns: 3fr;
  border: 1px solid grey;
}
.grid_container{
  display: grid;
  justify-content: center; /* position of the content of the grid */
  align-items: start; /* position of the cells within the grid areas */

  /* Defines columns and rows */
  /* grid-template-columns: 10%;
  grid-template-rows: 25%; */

  /*  */
  grid-template-columns: 10% 10% 10% 10%;
  grid-auto-columns: 20%;
  grid-auto-flow: row;

  column-gap: 10px;
  row-gap: 10px;

  border: 1px solid grey;
}

.grid_item {
  border: 1px solid blue;
}

.isRowHighlight {
  background-color: rgba(0,0,0,0.1);
}

.isZero{
  color: rgba(0,0,0,0.2)
}

.isHardCoded {
  color: blue;
}
.isFormula{
  text-decoration: underline!important;
}

.isNotSynced{
  border: 1px solid orange;
  box-shadow: 0px 0px 15px orange;
}

.isError{
  background-color: red;
}

.isCircular{
  background-color: orange;
}

.clickable{
  cursor: pointer;
}
